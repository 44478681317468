import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import watermark from "../../assets/images/watermarkbw.png";
import logo from "../../assets/images/Okulr_LogoPdf.png";
import Loader from "../../assets/gif/Loader.gif";
import { useCustomer } from "../../context/CustomerProvider";

import TimeFormatter from "../../utils/gloablDateTimeFunction";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
import { useEmployee } from "../../context/EmpProvider";

const AttendanceDateWise = ({ open, handleClose, filterType }) => {
  const [logData, setLogData] = useState([]);
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  const { getTimeByCountry } = useAttendanceTable();
  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  const [recordsFound, setRecordsFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const { selectedDate } = useEmployee();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  };

  const formatSnippet = (snippet) => {
    return snippet ? `data:image/jpeg;base64,${snippet}` : "";
  };

  const generateReport = async () => {
    try {
      const dataForSummeryApi = logData;

      const doc3 = new jsPDF({
        orientation: "landscape", // Set landscape orientation for more width
        unit: "mm",
        format: [297, 210], // Custom size, 297mm width and 210mm height (A4 landscape)
      });

      if (companyLogo) {
        // Load the logo image to calculate its dimensions
        const logoImage = new Image();
        logoImage.src = companyLogo; // Assuming companyLogo is a base64 encoded image

        logoImage.onload = async () => {
          const originalWidth = logoImage.width;
          const originalHeight = logoImage.height;

          // Set maximum dimensions
          const maxWidth = 70; // Adjust maximum width
          const maxHeight = 35; // Adjust maximum height

          let logoWidth = originalWidth;
          let logoHeight = originalHeight;

          // Calculate aspect ratio and adjust width/height to fit within max dimensions
          const aspectRatio = originalWidth / originalHeight;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          // Add the logo to the PDF with the adjusted size
          doc3.addImage(companyLogo, "JPEG", 10, 10, logoWidth, logoHeight);

          const tableColumn3 = [
            "S.No.",
            "Date",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
          ];

          // Prepare table rows with placeholders for images
          const tableRows3 = dataForSummeryApi.map((member, index) => {
            return [
              index + 1,
              formatDate(member.date) || "--",
              member.employeeId || "--",
              member.employeeName || "--",
              member.shiftTiming || "--",

              // member?.firstInTime
              //   ? new Date(member?.firstInTime).toLocaleTimeString()
              //   : "N/A" || "--",
              customer?.data?.country && member?.firstInTime
                ? getTimeByCountry(
                    customer?.data?.country,
                    member?.firstInTime,
                    customer?.data?.state,
                    member?.shiftTiming
                  )?.newTimePost18Conditionally?.newTimeLate
                : "NA" || "--",

              "", // Placeholder for image
              // formatTimeAtt(member.lastOutTime) || "--",

              customer.data.country &&
              member.lastOutTime &&
              member.lastOutTime !== "N/A"
                ? getTimeByCountry(
                    customer?.data?.country,
                    member?.lastOutTime,
                    customer?.data?.state,
                    member?.shiftTiming
                  )?.newTimePost18Conditionally?.newTimeLate
                : "NA" || "--",
              "", // Placeholder for image
            ];
          });

          const pageWidth = doc3.internal.pageSize.width;
          const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25;

          const leftMargin = (pageWidth - totalTableWidth) / 2;
          const title = "In-Out Time Attendance Summary"; // Define the title
          const textWidth = doc3.getTextWidth(title); // Get the width of the text
          const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

          doc3.text(title, textX, 40);
          doc3.setTextColor(46, 46, 46);

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { top: 50, left: leftMargin, right: 10 },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            headStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 30 },
              2: { cellWidth: 35 },
              3: { cellWidth: 35 },
              4: { cellWidth: 25 },
              5: { cellWidth: 20 },
              6: { cellWidth: 30 },
              7: { cellWidth: 23 },
              8: { cellWidth: 30 },
            },
            didDrawCell: (data) => {
              if (
                data.section === "body" &&
                (data.column.index === 6 || data.column.index === 8)
              ) {
                // console.log("row check", data.row.index);

                const rowIndex = data.row.index;
                if (rowIndex >= 0 && rowIndex < dataForSummeryApi.length) {
                  const member = dataForSummeryApi[rowIndex];
                  const imageBase64 =
                    data.column.index === 6
                      ? member.inTimeSnippet
                      : member.outTimeSnippet;

                  if (imageBase64) {
                    try {
                      doc3.addImage(
                        `data:image/jpeg;base64,${imageBase64}`,
                        "JPEG",
                        data.cell.x + 2,
                        data.cell.y + 2,
                        15,
                        15
                      );
                    } catch (error) {
                      console.log("Error adding image:", error);
                    }
                  }
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          doc3.save(`attendance.pdf`);
        };
        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadReport = async (filterType) => {
    try {
      if (!filterType) {
        // console.log("No filterType provided");
        return;
      }

      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/filter-livetracker`,
        {
          params: {
            filterType: filterType,
            startDateFilter: selectedDate?.startDate,
            endDateFilter: selectedDate?.endDate,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response?.statusText === "OK") {
        setRecordsFound(response.data.length > 0);
        setLogData(response?.data);
      }
    } catch (error) {
      setLoading(false);
      setRecordsFound(false);
      console.error("Error fetching attendance reports:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && filterType) {
      //   console.log("checkFilterType", filterType);
      downloadReport(filterType);
    }
  }, [open, filterType]);

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Snippet" },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="attendanceTable attendanceTableLog">
        {loading ? (
          <div className="logDetailsLoader">
            <img src={Loader} alt="Loading" />
          </div>
        ) : (
          <>
            <Box className="list ListlogDetails">
              {recordsFound ? (
                <>
                  <table>
                    <thead>
                      <tr className="tableAttendanceHeading">
                        {tableHeading.map((h) => (
                          <th key={h.id}>{h.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {logData.map((data, index) => (
                        <tr key={index} className="tableDataName">
                          <td>{index + 1}</td>

                          <td>{formatDate(data.date)}</td>
                          <td>{data.employeeId}</td>
                          <td>{data.employeeName}</td>
                          <td>{data?.shiftTiming}</td>
                          <td>
                            {customer?.data?.country && data?.firstInTime
                              ? getTimeByCountry(
                                  customer?.data?.country,
                                  data?.firstInTime,
                                  customer?.data?.state,
                                  data?.shiftTiming
                                )?.newTimePost18Conditionally?.newTimeLate
                              : "NA" || "--"}
                          </td>
                          <td>
                            {data.inTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={formatSnippet(data.inTimeSnippet)}
                                alt="in-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            {customer.data.country &&
                            data.lastOutTime &&
                            data.lastOutTime !== "N/A"
                              ? getTimeByCountry(
                                  customer?.data?.country,
                                  data?.lastOutTime,
                                  customer?.data?.state,
                                  data?.shiftTiming
                                )?.newTime
                              : "NA" || "--"}
                          </td>

                          <td>
                            {data.outTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={formatSnippet(data.outTimeSnippet)}
                                alt="out-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <Box className="noRecordsFound">
                  <p
                    style={{
                      height: "50%",
                      width: "50%",
                      top: "50%",
                      left: "25%",
                      textAlign: "center",
                      position: "absolute",
                    }}
                  >
                    <h3> No records found.</h3>
                  </p>
                </Box>
              )}
            </Box>
            {logData.length > 0 && (
              <Box
                className="button_download"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button onClick={generateReport} className="downloadBtn">
                  <span>Download</span>
                </button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AttendanceDateWise;
